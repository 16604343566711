import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {map, tap} from 'rxjs/operators';

@Component({
  selector: 'app-vacatures-detail',
  templateUrl: './vacatures-detail.component.html',
  styleUrls: ['./vacatures-detail.component.scss']
})
export class VacaturesDetailComponent {

  vacature$: Observable<any>;
  private _jsonURL = 'assets/vacatures.json';
  constructor(private http: HttpClient, private router: ActivatedRoute) {
    this.router.params.subscribe(params => {
      this.vacature$ =this.getJSON(params)
    })
  }
  public getJSON(params: Params): Observable<any> {
    return this.http.get<any[]>(this._jsonURL).pipe(map(data => {
      return data.filter(vacature => Number(vacature.id) === Number(params.id))[0]
    }));
  }
}
